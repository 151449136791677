.NavbarItems {
    background: linear-gradient(90deg, rgb(74, 78, 77) 0%, rgba(82,82,82,100%));
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-title {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: context-menu;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    grid-gap: 1.5rem;
    top: -500px;
    right: 0;
    padding : 1rem;
    margin: 0;
    transition: all 0.5s ease;
    z-index: -2;
}

.dropdown.active {
    background: #3da4ab;
    position: absolute;
    top: 80px;
    right: 0;
    padding: 1rem;
    margin: 0;
    transition: all 0.5s ease;
    z-index: -2;
}

.nav-links {
    display: flex;
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #0e9aa7;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.dropdown-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.dropdown-links:hover {
    background-color: #f6cd61;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.link {
    text-decoration: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {

    .NavbarItems{
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
        width: 100%;
        position: absolute;
        top: -500px;
        left: 0;
        padding : 0;
        margin: 0;
        transition: all 0.5s ease;
        z-index: -1;
    }

    .nav-menu.active {
        background: none;
        position: absolute;
        top: 80px;
        left: 0;
        padding: 0;
        margin: 0;
        transition: all 0.5s ease;
        z-index: -1;
    }

    .dropdown {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
        width: 100%;
        position: relative;
        top: -900px;
        left: 0;
        padding : 0;
        margin: 0;
        transition: all 0.5s ease;
        z-index: -2;
    }

    .dropdown.active {
        background: #3da4ab;
        position: relative;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
        transition: all 0.5s ease;
        z-index: -2;
    }

    .nav-links {
        background: #0e9aa7;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        display: table;
        position: relative;
    }

    .dropdown-links {
        text-align: center;
        font-size: 1rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        display: table;
        position: relative;
    }

    .nav-links:hover {
        background: #0e9aa7;
        border-radius: 0;
    }

    .dropdown-links:hover {
        background-color: #3da4ab;
        border-radius: 0;
        
    }

    .navbar-title {
        display: block;
        position: absolute;
        left: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-bars {
        color: #fff;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

}

@media screen and (max-width: 280px) {

    .navbar-title{
        display: none;
    }
}